import axios from 'axios';
import { decryptResponse, encryptPayload } from '../utils/cryptoUtils';

const token = localStorage.getItem('token')

const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_SALES_URL,
    headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to encrypt the payload
apiInstance.interceptors.request.use(async (config) => {
    // Encrypt the payload if it exists and content-type=application/json
    if (config.headers['Content-Type'] === 'application/json' && config.data) {
        let encryptedRequest = encryptPayload(config.data);
        config.data = { data: encryptedRequest };
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// decrypt the response
apiInstance.interceptors.response.use(async (response) => {
    const decryptedData = decryptResponse(response.data.response);
    response.data = decryptedData;
    return response;
}, async error => {
    if (error.code === 'ERR_NETWORK') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'No Internet Connection',
                    response_id: "9939393"
                },
                body: null
            }
        };

        return errorData
    } else if (error.code === 'ECONNABORTED') {
        var errorData = {
            data: {
                headers: {
                    status: false,
                    status_code: 503,
                    status_message: 'No Internet Connection',
                    response_id: "9939393"
                },
                body: null
            }
        };
        return errorData
    } else {
        let errorEncrypted = decryptResponse(error.response.data.response);
        return Promise.reject(errorEncrypted);
    }
});

export default apiInstance