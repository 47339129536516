import React from "react";
import {
  Route,
  RouterProvider,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./screens/Home";
import FieldSales from "./screens/FieldSales";
import Login from "./screens/Login";
import GoogleLogin from "./screens/GoogleLogin";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";
import ErrorPage from "./screens/ErrorPage/ErrorPage";
import { Toaster } from "react-hot-toast";
import "./index.css";

function App() {
  const { isAuthenticated } = useContext(UserContext);
  const isAuth = localStorage.getItem("isAuth");
  const isGoogleAuth = localStorage.getItem("isGoogleAuth")

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Outlet />} errorElement={<ErrorPage />}>
        <Route path="/" element={isAuth ? <Home /> : <Login />} />
        <Route path="/fieldsales" element={isGoogleAuth ? <FieldSales /> : <GoogleLogin />} />
      </Route>
    )
  );

  React.useEffect(() => { }, [isAuthenticated]);

  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

export default App;
