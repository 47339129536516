import IMAGES from "../../assets";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ErrorResponse, IItems } from "../../interfaces/PermisesListResponse";
import { COLORS } from "../../utils/Colors";
import { CONSTANTS } from "../../utils/Constants";
import MiniMap from "../Map/MiniMap";
import Spinner from "../Spinner/Spinner";

type DetailCardProps = {
  selectedPermise: IItems | undefined;
  selectedPremiseError: ErrorResponse | undefined;
  loading: boolean;
};

const DetailCard = (props: DetailCardProps) => {
  const { height } = useWindowDimensions();
  return (
    <div
      className="flex absolute z-10 top-16 left-10 flex-col bg-blue-50 w-96 h-5/6 gap-2 rounded-xl items-center"
      style={{
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        height: height * 0.9,
        scrollbarWidth: "thin",
      }}
    >
      <div
        className="flex h-1/6 justify-center rounded-b-2xl rounded-t-xl w-full"
        style={{ background: COLORS.Brand }}
      >
        <span className="text-2xl font-semibold text-left text-white mt-3">
          {CONSTANTS.permisesDetails}
        </span>
      </div>

      <div
        className="flex flex-col flex-1 items-center justify-center w-11/12 absolute top-16 bg-white rounded-xl drop-shadow-sm"
        style={{ height: "92%" }}
      >
        {props.loading ? (
          <div className="flex flex-col flex-1 border-t-2 border-t-black items-center justify-center">
            <Spinner />
          </div>
        ) : props.selectedPremiseError ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <img src={IMAGES.homeWarning} alt="ERROR" width="100px" />
            <p className="text-lg text-center">{CONSTANTS.unexpectedError}</p>
            <p>{props.selectedPremiseError?.detail}</p>
          </div>
        ) : props.selectedPermise === undefined ? (
          <div className="flex flex-1 flex-col items-center self-centers justify-center w-7/12 ">
            <img src={IMAGES.note} alt="ERROR" width="80px" />
            <p className="text-lg text-center">
              {CONSTANTS.noSelectedPermiseTitle}
            </p>
          </div>
        ) : (
          <div className="flex flex-col flex-1 justify-start gap-2 text-sm px-4  w-full h-full ">
            <div className="flex justify-between items-center w-full">
              <img src={IMAGES.product} width={height * 0.12} alt="Product" />
              <div className="flex flex-col items-center gap-3">
                <span>
                  Latitude: {props.selectedPermise.detail.latitude.toFixed(3)}
                </span>
                <span>
                  Longitude: {props.selectedPermise.detail.longitude.toFixed(3)}
                </span>
              </div>
            </div>
            {props.selectedPermise && (
              <MiniMap selectedPremise={props.selectedPermise} />
            )}
            <div className="flex gap-3 flex-col overflow-y-auto" style={{}}>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Farm Managment
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.farm_management}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Ownership
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.ownership_of_farm}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Water Source
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.current_water_source}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Water Availability
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.has_water ? "Yes" : "No"}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Water depth
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.depth_of_water_source}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Water source distance
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.distance_to_water_source}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Monthly pumping cost
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.monthly_pumping_cost}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  irrigation method
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.current_irrigation_method}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Farm size (in Acres)
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.total_farm_size_acres}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Soil test
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.did_soil_test ? "Yes" : "No"}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Water tank capacity
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.water_tank_capacity_liter}
                </span>
              </div>
              <div className="flex items-center justify-between gap-2  w-full flex-col">
                <label htmlFor=" " className="text-xs w-full">
                  Electricity Availability
                </label>
                <span className="font-semibold px-4 py-3 w-full rounded-lg bg-gray-100 text-xs ">
                  {props.selectedPermise.detail.electricity_on_farm
                    ? "Yes"
                    : "No"}
                </span>
              </div>

              <span className=" font-bold  w-full">CROPS GROWN</span>
              <div className="flex flex-col gap-4 ">
                {props.selectedPermise.crops.length > 0 ? (
                  props.selectedPermise.crops.map((item) => {
                    return (
                      <div className="flex flex-col gap-2 justify-center border-b-gray-300 border-black py-3">
                        <div className="flex flex-1 justify-between items-center px-2">
                          <span className="">Crop name</span>
                          <span className="font-semibold">
                            {item.crop_name}
                          </span>
                        </div>
                        <div className="flex flex-1 justify-between items-center px-2">
                          <span className="">% of Land</span>
                          <span className="font-semibold">
                            {item.land_percentage}
                          </span>
                        </div>
                        <div className="flex flex-1 justify-between items-center px-2">
                          <span className="">Expected Harvest Month</span>
                          <span className="font-semibold">
                            {item.expected_harvest_month}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex justify-center items-center h-20">
                    <span>No Crops data available</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailCard;
