import { Libraries } from "@react-google-maps/api";

export const calculateCenter = (coordinates: any) => {
  // Calculate the center latitude and longitude
  const center = coordinates.reduce(
    (accumulator: { lat: any; lng: any }, point: { lat: any; lng: any }) => {
      accumulator.lat += point.lat;
      accumulator.lng += point.lng;
      return accumulator;
    },
    { lat: 0, lng: 0 }
  );

  center.lat /= coordinates.length;
  center.lng /= coordinates.length;

  return center;
};

export const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API;

export const REACT_MAP_LIBRARIE: Libraries = ["visualization"];
