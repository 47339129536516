import { useRouteError } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { CONSTANTS } from "../../utils/Constants";

const ErrorPage = () => {
  const error: any = useRouteError();

  return (
    <PageWrapper title={"error"}>
      <div className="flex flex-col items-center justify-center">
        <h1>{CONSTANTS.oops}</h1>
        <p>{CONSTANTS.unexpectedError}</p>
        <p>
          <i>{error.status + " " + error.statusText}</i>
        </p>
      </div>
    </PageWrapper>
  );
};

export default ErrorPage;
