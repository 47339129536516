import React from "react";

interface IPagerapper {
  children: React.ReactNode;
  title: string;
  style?: React.CSSProperties;
}

const PageWrapper = (props: IPagerapper) => {
  React.useEffect(() => {
    document.title = props.title + ` | ` + process.env.REACT_APP_SITE_NAME;
  }, [props]);

  return (
    <div className="flex w-screen min-h-screen items-center" {...props}>
      {props.children}
    </div>
  );
};

export default PageWrapper;
