import React, { useEffect } from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import IMAGES from "../../assets";
import { CONSTANTS } from "../../utils/Constants";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const GoogleLogin = () => {
    const navigate = useNavigate()
    const { width } = useWindowDimensions();

    useEffect(() => {
        const checkQueryParams = () => {
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has('params')) {
                const params: any = queryParams.get('params')
                const decodedText = atob(params)
                const keyValuePairs = decodedText.split("&&");
                const values: any = {};
                keyValuePairs.forEach(pair => {
                    pair = pair.replace(/\0/g, '');
                    const [key, value] = pair.split("=").map(str => str.trim());
                    values[key] = value;
                });
                const token = values['token'];
                const status = values['status'];
                if (status === "true" && token !== null) {
                    localStorage.setItem("isGoogleAuth", "true")
                    localStorage.setItem("token", token)
                    navigate("/fieldsales")
                    navigate(0)
                } else {
                    toast.error(CONSTANTS.loginError, {
                        position: "top-center",
                        duration: 3000,
                        className: "danger",
                    });
                    navigate('/fieldsales')
                }
            }
        };
        checkQueryParams();
    }, []);

    const loginOauth = () => {
        window.location.href = `${process.env.REACT_APP_API_SALES_URL}/auth/google`;
    }

    return (
        <PageWrapper
            title="Login"
            style={{
                justifyContent: "center",
                background:
                    "linear-gradient(to right bottom, #e4f2ec, #c5f0db, #a7ecc6, #8be7ae, #71e293, #6be398, #64e49d, #5de5a2, #78ebc4, #9defdd, #c6f1ec, #f0f2f2)",
            }}>
            <div
                className="flex flex-col justify-center items-center rounded-8 shadow-lg gap-8 bg-white min-h-fit py-10 rounded-md font-poppins"
                style={{
                    width:
                        window.innerWidth <= 768
                            ? width * 0.8
                            : window.innerWidth > 1260
                                ? width * 0.3
                                : width * 0.4,
                }}>
                <div className="flex flex-col justify-center items-center gap-8">
                    <img
                        className="img"
                        width="135px"
                        src={IMAGES.logo}
                        alt="Sunculture"
                    />

                    <span className="text-2xl font-bold "> {CONSTANTS.marifa}</span>
                    <span className="text-1xl font-normal text-center px-10">
                        {CONSTANTS.googleLoginDesc}
                    </span>
                </div>
                <div className="flex flex-col justify-center items-center gap-5 w-full px-12">
                    <div
                        id="g_id_onload"
                        data-client_id="231123774692-042gu28opkqjqoe357egnqspn6ir027b.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleSignInWithGoogle"
                        data-nonce=""
                        data-auto_select="true"
                        data-itp_support="true"></div>
                    <button
                        title={CONSTANTS.googleLogin}
                        type="button"
                        onClick={loginOauth}
                        className="text-white w-full hover:cursor-pointer bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-3 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
                        <svg className="mr-2 -ml-1 w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                            <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                        Sign in with Google<div></div>
                    </button>
                </div>
            </div>
            <Toaster />
        </PageWrapper>
    );
};

export default GoogleLogin;
