import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { AxiosError } from "axios";
import React from "react";
import IMAGES from "../../assets";
import DetailCard from "../../components/DetailCard/DetailCard";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Spinner from "../../components/Spinner/Spinner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  ErrorResponse,
  IItems,
  IPermisesList,
} from "../../interfaces/PermisesListResponse";
import ENDPOINTS from "../../services/Endpoints";
import axiosInstance from "../../services/axiosInstance";
import { CONSTANTS } from "../../utils/Constants";
import { MAP_API_KEY, REACT_MAP_LIBRARIE } from "../../utils/Helpers";

const Home = () => {
  const { width, height } = useWindowDimensions();

  const refMap = React.useRef<GoogleMap | null>(null);

  const [heatLayerVisible, setHeatLayerVisible] = React.useState(true);

  const [loading, setLoading] = React.useState(false);
  const [center] = React.useState({ lat: 7.7832, lng: 24.5085 });

  const [permisesList, setPermisesList] = React.useState<IPermisesList>();
  const [heatMapData, setheatMapData] =
    React.useState<{ lat: number; lng: number }[]>();

  const [selectedPermise, setselectedPermise] = React.useState<IItems>();
  const [selectedPremiseError, setSelectedPremiseError] =
    React.useState<ErrorResponse>();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY || "",
    libraries: REACT_MAP_LIBRARIE,
  });

  const getSelectedpermiseDetails = (id: string) => {
    setLoading(true);
    axiosInstance
      .get(ENDPOINTS.getPermisesList + "/" + id, {
        headers: {
          accept: "application/json",
          employee_id: 21,
        },
      })
      .then((res) => {
        setselectedPermise(res.data);
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        setSelectedPremiseError(err.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (heatMapData) {
      setTimeout(() => {
        refMap.current?.state?.map?.setZoom(10);
        setTimeout(() => {
          refMap.current?.state?.map?.setZoom(4);
        }, 500);
      }, 500);
    }
  }, [heatMapData]);

  React.useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(ENDPOINTS.getPermisesList, {
        params: {
          page: 1,
          size: 5000,
        },
        headers: {
          employee_id: 21,
        },
      })
      .then((res) => {
        const list: IPermisesList = res.data;
        const latlng: React.SetStateAction<{ lat: number; lng: number }[]> = [];
        if (list.items.length > 0) {
          setPermisesList(list);
          list.items.forEach((item) => {
            if (item.detail?.latitude && item.detail?.longitude) {
              latlng.push({
                lat: item.detail.latitude,
                lng: item.detail.longitude,
              });
            }
          });
          setheatMapData(latlng);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleZoomChanged = () => {
    if (refMap.current) {
      const newZoom = refMap.current?.state.map?.getZoom();
      if (newZoom) {
        if (newZoom < 10) {
          setHeatLayerVisible(true);
        } else {
          setHeatLayerVisible(false);
        }
      }
    }
  };

  const renderHeatLayer = React.useCallback(() => {
    if (heatMapData) {
      if (heatMapData.length > 0) {
        return (
          <HeatmapLayer
            onLoad={(res) => { }}
            onUnmount={(res) => { }}
            options={{
              radius: 30,
              opacity: heatLayerVisible ? 1 : 0,
            }}
            data={heatMapData.map(
              (point) => new window.google.maps.LatLng(point.lat, point.lng)
            )}
          />
        );
      } else {
        return null;
      }
    }
  }, [heatLayerVisible]);

  const renderMarker = React.useCallback(() => {
    if (permisesList)
      return permisesList.items.map((item: IItems, index: number) => {
        if (item.detail) {
          if (item.detail.latitude && item.detail.latitude) {
            return (
              <Marker
                icon={{
                  scaledSize: new window.google.maps.Size(25, 25),
                  url: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
                }}
                opacity={heatLayerVisible ? 0 : 1}
                clickable
                onClick={() => getSelectedpermiseDetails(item.id)}
                key={index}
                position={{
                  lat: item.detail.latitude,
                  lng: item.detail.longitude,
                }}
              />
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
  }, [heatLayerVisible, permisesList]);

  return (
    <PageWrapper title="Home">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width,
            height,
            borderRadius: 10,
          }}
          center={center}
          zoom={4}
          onLoad={(map) => {
            map.setZoom(4);
          }}
          ref={refMap}
          options={{
            fullscreenControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.DEFAULT,
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            minZoom: 3,
          }}
          onZoomChanged={handleZoomChanged}
        >
          {renderMarker()}
          {renderHeatLayer()}
          <img
            src={IMAGES.logo}
            className="z-10 absolute top-3 right-5 w-1/6"
            alt={CONSTANTS.appTitle}
          />
          <DetailCard
            loading={loading}
            selectedPermise={selectedPermise}
            selectedPremiseError={selectedPremiseError}
          />
        </GoogleMap>
      ) : (
        <div className="flex h-screen w-screen justify-center items-center">
          <Spinner />
        </div>
      )}
    </PageWrapper>
  );
};

export default Home;
