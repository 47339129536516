import CryptoJS from 'crypto-js';

export const encryptPayload = (payload: any) => {
    const jsonString = JSON.stringify(payload);
    const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
    if (encryptKey) {
        const encrypted = CryptoJS.AES.encrypt(jsonString, encryptKey).toString();
        return encrypted;
    } else {
        throw new Error('Encryption key is undefined');
    }
};


export const decryptResponse = (encryptedResponse: any) => {
    const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;
    if (encryptKey) {
        const decrypted = CryptoJS.AES.decrypt(encryptedResponse, encryptKey).toString(CryptoJS.enc.Utf8);
        const decryptedJson = JSON.parse(decrypted);
        return decryptedJson;
    } else {
        throw new Error('Encryption key is undefined');
    }
};