import React, { useState, useEffect } from 'react';
import { IAgentItems } from '../../interfaces/AgentListResponse';

interface DropdownProps {
    options: IAgentItems[];
    onSelect: (option: string) => void;
    selectedAgentId: string;
    onOpen: (isOpen: boolean) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onSelect, selectedAgentId, onOpen }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [agentName, setAgentName] = useState<String>("")

    const filteredOptions = options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const handleSelect = (option: IAgentItems) => {
        onSelect(option.agentId);
        setAgentName(option.name)
        setIsOpen(false);
        onOpen(false)
    };

    useEffect(() => {
        const filteredAgent = options.filter(option => option.agentId === selectedAgentId);
        if (filteredAgent.length > 0) {
            onSelect(filteredAgent[0].agentId);
            setAgentName(filteredAgent[0].name)
        }
    }, [selectedAgentId])

    return (
        <>
            <div className="relative inline-block text-left w-full z-10">

                <div className='z-10'>
                    <button
                        type="button"
                        className="z-10 inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        onClick={() => {
                            setIsOpen(!isOpen)
                            onOpen(!isOpen)
                        }}>
                        {agentName === "" ? "Select an agent" : agentName}
                        <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true">
                            <path
                                fillRule="evenodd"
                                d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                {isOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-full z-10  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <div className="flex items-center px-4 pt-2">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    style={{ border: '1px solid lightgray' }}
                                    className="w-full border-slate-200 focus:ring-indigo-500 focus:border-indigo-500 rounded-md shadow-sm p-2"
                                    placeholder="Search..."
                                />
                            </div>
                            <div className="max-h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-red-400 scrollbar-track-red-100">
                                {filteredOptions.map(option => (
                                    <div
                                        key={option.agentId}
                                        onClick={() => handleSelect(option)}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Dropdown
