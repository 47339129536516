import React from "react";

interface UserContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

interface IuserProvider {
  children: React.ReactNode;
}

const initialAuthState: boolean = false;

export const UserContext = React.createContext<UserContextType>({
  isAuthenticated: initialAuthState,
  setIsAuthenticated: () => {},
});

const UserProvider = (props: IuserProvider) => {
  const [isAuthenticated, setIsAuthenticated] =
    React.useState<boolean>(initialAuthState);

  return (
    <UserContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
