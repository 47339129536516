export const CONSTANTS = {
  welcome: "Welcome to Sunculture",
  appTitle: "A data platform by SunCulture",
  marifa: "MAARIFA",
  enterCode: "Enter your login code to continue",
  login: "Login",
  logout: "Logout",
  permisesDetails: "Premises Details",
  noSelectedPermiseTitle:
    "Please select a permise on the map to get the details ",
  unexpectedError: "Sorry, an unexpected error has occurred.",
  loginError: "Login code doesn't match Please try again ",
  oops: "Oops!",
  googleLogin: "Google Login",
  googleLoginDesc: "Use your google login account to autheticate your request to view the field sales",
  filter: "Filters"
};
