import { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { COLORS } from "../../utils/Colors";
import { CONSTANTS } from "../../utils/Constants";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "../Dropdown";
import { IAgentItems } from "../../interfaces/AgentListResponse";
import LinearProgress from "../LinearProgress";

export interface FilterValues {
    startDate: any | null;
    endDate: any | null;
    loggingType: any | null;
    agentReference: any | null;
}

export interface ErrorValues {
    status: boolean | false;
    msg: string | "";
}

interface CardFilterProps {
    onFilter: (values: FilterValues) => void;
    onClearFilter: () => void;
    error: ErrorValues;
    selectedAgentId: string
    agents: IAgentItems[]
    loading: boolean
}

const FilterCard: React.FC<CardFilterProps> = ({ loading, onFilter, onClearFilter, error, selectedAgentId, agents }) => {
    const [open, setOpen] = useState<boolean>(false)

    const handleSelect = (option: string) => {
        setFilterValues({ ...filterValues, agentReference: option });
    };

    const handleOnOpen = (option: boolean) => {
        setOpen(option)
    }

    const initialFilterValues: FilterValues = {
        startDate: null,
        endDate: null,
        loggingType: null,
        agentReference: null
    };

    const { height } = useWindowDimensions();

    const [filterValues, setFilterValues] = useState<FilterValues>(initialFilterValues);

    const handleFilterClick = () => {
        onFilter(filterValues);
    };

    const handleClearFilterClick = () => {
        setFilterValues(initialFilterValues);
        onClearFilter();
    }

    return (
        <div
            className={`flex absolute top-16 left-10 flex-col bg-blue-50 w-96 h-5/6 gap-2 rounded-xl items-center font-poppins`}
            style={{
                boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                height: height * 0.9,
                scrollbarWidth: "thin",
            }}>

            <div
                className="flex h-1/6 justify-center rounded-b-2xl rounded-t-xl w-full"
                style={{ background: COLORS.Brand }}>
                <span className="text-2xl font-semibold text-left text-white mt-3">
                    {CONSTANTS.filter}
                </span>
            </div>

            <div
                className="flex flex-col flex-1 items-center p-5 w-11/12 absolute top-16 bg-white rounded-xl drop-shadow-sm"
                style={{ height: "92%" }}>

                {error.status === true && <div className="col-span-12 text-[13px] mt-10 w-full text-red-700 bg-red-300 py-2 px-3 rounded-md">
                    {error.msg}
                </div>}
                {loading === true && <LinearProgress />}

                <div className="col-span-12 mt-8 w-full">

                    <label htmlFor="username" className="block text-sm font-medium text-gray-900">Choose Agent</label>
                    <div className="mt-1">
                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                            {open && <div className='absolute top-0 left-0 w-full h-full bg-slate-500 opacity-20 rounded-t-xl' />}
                            <Dropdown options={agents} onSelect={handleSelect} selectedAgentId={selectedAgentId} onOpen={handleOnOpen} />
                        </div>
                    </div>
                </div>

                <div className="col-span-12 w-full mt-8">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">Choose LogType</label>
                    <div className="mt-1">
                        <select id="loggingType" name="loggingType" autoComplete="loggingType"
                            value={filterValues.loggingType}
                            onChange={(e: any) => {
                                setFilterValues({ ...filterValues, loggingType: e.target.value });
                            }}
                            className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 px-2 bg-white">
                            <option value="" disabled selected className="text-slate-500">--Choose a Logging Type--</option>
                            <option value={'periodic_logging'}>Periodic Logging</option>
                            <option value={'new_lead'}>New Lead</option>
                            <option value={'cds_1'}>CDS 1</option>
                            <option value={'kyc'}>KYC</option>
                        </select>
                    </div>
                </div>

                <div className="mt-8 grid grid-cols-1 gap-x-3 gap-y-1 grid-cols-6 col-span-12">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                        <div className="mt-1">
                            <DatePicker
                                title="Start Date"
                                placeholderText="dd/MM/yyyy"
                                selected={filterValues.startDate}
                                onChange={(date: any) => {
                                    setFilterValues({ ...filterValues, startDate: date });
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                        <div className="mt-1">
                            <DatePicker
                                title="End Date"
                                placeholderText="dd/MM/yyyy"
                                selected={filterValues.endDate}
                                onChange={(date: any) => {
                                    setFilterValues({ ...filterValues, endDate: date });
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="block w-full rounded-md border-0 px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                </div>

                <div className={`mt-10 flex items-center justify-end gap-x-6 w-full`}>
                    <button type="submit"
                        disabled={filterValues.agentReference === null && filterValues.agentReference === null && filterValues.startDate === null && filterValues.endDate === null ? true : false}
                        className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full
                        ${filterValues.agentReference === null && filterValues.agentReference === null && filterValues.loggingType === null && filterValues.startDate === null && filterValues.endDate === null ? 'bg-slate-400 cursor-not-allowed' : 'bg-green-600 hover:cursor-pointer hover:bg-green-500'}`}
                        onClick={handleFilterClick}>Filter</button>
                </div>

                <div className="mt-5 flex items-center justify-end gap-x-6 w-full">
                    <button type="submit"
                        onClick={handleClearFilterClick} className="rounded-md px-3 py-2 text-sm font-semibold text-red-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 w-full" style={{ border: '1px solid red' }}>Clear</button>
                </div>

            </div>
        </div>
    );
};

export default FilterCard;
