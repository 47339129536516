import {
    GoogleMap,
    HeatmapLayer,
    Marker,
    useJsApiLoader,
    InfoWindow
} from "@react-google-maps/api";
import React, { useState } from "react";
import IMAGES from "../../assets";
import FilterCard, { FilterValues, ErrorValues } from "../../components/FilterCard";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Spinner from "../../components/Spinner/Spinner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ILoggingList, ILoggingItems, } from "../../interfaces/LoggingListResponse";
import ENDPOINTS from "../../services/Endpoints";
import apiInstance from "../../services/apiInstance";
import { CONSTANTS } from "../../utils/Constants";
import { MAP_API_KEY, REACT_MAP_LIBRARIE } from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";;

const FieldSales = () => {
    const navigate = useNavigate()
    const [error, setError] = useState<ErrorValues>({ status: false, msg: '' })
    const [selectedAgentId, setSelectedAgentId] = useState<any>("")
    const [agents, setAgents] = useState([])

    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const initialFilterValues: FilterValues = {
        startDate: null,
        endDate: null,
        loggingType: null,
        agentReference: null
    };
    const { width, height } = useWindowDimensions();

    const refMap = React.useRef<GoogleMap | null>(null);
    const [filterValues, setFilterValues] = useState<FilterValues>(initialFilterValues);

    const handleFilter = (values: FilterValues) => {
        setFilterValues(values)
    };

    const handleClearFilter = () => {
        setError({ status: false, msg: '' })
        setFilterValues(initialFilterValues);
    };

    const [heatLayerVisible, setHeatLayerVisible] = React.useState(true);

    const [loading, setLoading] = React.useState(false);
    const [center] = React.useState({ lat: 7.7832, lng: 24.5085 });

    const [permisesList, setPermisesList] = React.useState<ILoggingList>();
    const [heatMapData, setheatMapData] =
        React.useState<{ lat: number; lng: number }[]>();

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: MAP_API_KEY || "",
        libraries: REACT_MAP_LIBRARIE,
    });

    const getSelectedpermiseDetails = (item: ILoggingItems) => {
        setSelectedMarker(item)
        setSelectedAgentId(item.agentReference)
    };


    React.useEffect(() => {
        setLoading(true)
        apiInstance.get(`${ENDPOINTS.getSalesAgents}`).then((res: any) => {
            setAgents(res.data.body)
            setFilterValues(initialFilterValues);
            setLoading(false)
        }).catch((err) => {
            setLoading(false);
            if (err.headers.status_code === 401) {
                localStorage.clear()
                navigate("fieldsales")
            }
            setTimeout(() => {
                setError({ status: true, msg: err.headers.status_message })
            }, 1000)

        });
    }, [])


    React.useEffect(() => {
        if (heatMapData) {
            setTimeout(() => {
                refMap.current?.state?.map?.setZoom(10);
                setTimeout(() => {
                    refMap.current?.state?.map?.setZoom(4);
                }, 500);
            }, 500);
        }
    }, [heatMapData]);

    React.useEffect(() => {
        fetchSalesDetails()
    }, [])

    React.useEffect(() => {
        fetchSalesDetails()
    }, [filterValues]);

    const fetchSalesDetails = async () => {
        setLoading(true);
        await apiInstance.get(`${ENDPOINTS.getSalesAgentsLogging}?agentReference=${filterValues.agentReference}&&logType=${filterValues.loggingType}&&startDate=${filterValues.startDate}&&endDate=${filterValues.endDate}`).then((res: any) => {
            const list: ILoggingList = res.data;

            const latlng: React.SetStateAction<{ lat: number; lng: number }[]> = [];
            if (list.body.length > 0) {
                setError({ status: false, msg: '' })
                setPermisesList(list);
                list.body.forEach((item) => {
                    if (item?.lat && item.lat !== 'null' && item.lng !== 'null' && item?.lng) {
                        latlng.push({
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                        });
                    }
                });
                setheatMapData(latlng);
                setLoading(false);
            } else {
                setheatMapData([])
                setLoading(false);
                setError({ status: true, msg: 'Oops we are unable to find the records that matches your search' })
            }
        }).catch((err) => {
            setLoading(false);
            setTimeout(() => {
                setError({ status: true, msg: err.headers.status_message })
            }, 1000)
            if (err.headers.status_code === 401) {
                localStorage.clear()
                navigate("fieldsales")
            }
        });
    }

    const handleZoomChanged = () => {
        if (refMap.current) {
            const newZoom = refMap.current?.state.map?.getZoom();
            if (newZoom) {
                if (newZoom < 10) {
                    setHeatLayerVisible(true);
                } else {
                    setHeatLayerVisible(false);
                }
            }
        }
    };

    const renderHeatLayer = React.useCallback(() => {
        if (heatMapData) {
            if (heatMapData.length > 0) {
                return (
                    <HeatmapLayer
                        onLoad={(res) => { }}
                        onUnmount={(res) => { }}
                        options={{
                            radius: 30,
                            opacity: heatLayerVisible ? 1 : 0,
                        }}
                        data={heatMapData.map((point) => new window.google.maps.LatLng(point.lat, point.lng))}
                    />
                );
            } else {
                return null;
            }
        }
    }, [heatLayerVisible]);

    const renderMarker = React.useCallback(() => {
        if (permisesList)
            return permisesList.body.map((item: ILoggingItems, index: number) => {
                if (item) {
                    if (item.lat && item.lng) {
                        return (
                            <>
                                <Marker
                                    icon={{
                                        scaledSize: new window.google.maps.Size(25, 25),
                                        url: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
                                    }}
                                    opacity={heatLayerVisible ? 0 : 1}
                                    clickable
                                    onClick={() => getSelectedpermiseDetails(item)}
                                    key={index}
                                    position={{
                                        lat: parseFloat(item.lat),
                                        lng: parseFloat(item.lng),
                                    }} />


                                {selectedMarker && <InfoWindow
                                    position={{ lat: parseFloat(selectedMarker.lat), lng: parseFloat(selectedMarker.lng) }}
                                    onCloseClick={() => {
                                        setSelectedMarker(null)
                                    }
                                    }>
                                    <div className="p-3">
                                        <span><b>Agent Ref ID:</b>{selectedMarker.agentReference}</span><br />
                                        <span><b>Latitude:</b>{selectedMarker.lat}</span><br />
                                        <span><b>Longitude:</b>{selectedMarker.lng}</span><br />
                                        <span><b>Log Type:</b> {selectedMarker.logType}</span></div>
                                </InfoWindow>
                                }
                            </>
                        );
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });


    }, [heatLayerVisible, permisesList, selectedMarker]);

    return (
        <PageWrapper title="Home">
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{
                        width,
                        height,
                        borderRadius: 10,
                    }}
                    center={center}
                    zoom={4}
                    onLoad={(map) => {
                        map.setZoom(4);
                    }}
                    ref={refMap}
                    options={{
                        fullscreenControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_LEFT,
                        },
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                            style: window.google.maps.MapTypeControlStyle.DEFAULT,
                            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                        },
                        minZoom: 3,
                    }}
                    onZoomChanged={handleZoomChanged}>
                    {renderMarker()}
                    {renderHeatLayer()}
                    <img
                        src={IMAGES.logo}
                        className="z-10 absolute top-3 right-5 w-1/6"
                        alt={CONSTANTS.appTitle}
                    />
                    <FilterCard
                        loading={loading}
                        onFilter={handleFilter}
                        onClearFilter={handleClearFilter}
                        error={error}
                        selectedAgentId={selectedAgentId}
                        agents={agents}
                    />
                </GoogleMap>
            ) : (
                <div className="flex h-screen w-screen justify-center items-center">
                    <Spinner />
                </div>
            )}
        </PageWrapper>
    );
};

export default FieldSales;
