import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import axiosInstance from "../../services/axiosInstance";
import { IItems } from "../../interfaces/PermisesListResponse";
import { IPremiseGPS } from "../../interfaces/PremiseGPS";
import {
  MAP_API_KEY,
  REACT_MAP_LIBRARIE,
  calculateCenter,
} from "../../utils/Helpers";

type MiniMapProsp = {
  selectedPremise: IItems;
};

const MiniMap = (props: MiniMapProsp) => {
  const { height } = useWindowDimensions();
  const [gpsPoints, setgpsPoints] = React.useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY || "",
    libraries: REACT_MAP_LIBRARIE,
  });

  const onLoad = React.useCallback(function callback(map: any) {
  
    map.setZoom(14);
  }, []);

  React.useEffect(() => {
    axiosInstance
      .get(`premises/${props.selectedPremise.detail.premise_id}/gps/`, {
        params: {
          page: 1,
          size: 25,
        },
        headers: {
          employee_id: 21,
        },
      })
      .then((res) => {
        const gpsList: IPremiseGPS = res.data;
        if (gpsList) {
          gpsList.items.length > 0 &&
            setgpsPoints(
              gpsList.items.map((item) => ({
                lat: item.latitude,
                lng: item.longitude,
              }))
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: height * 0.35, width: "100%" }}
      center={
        gpsPoints.length > 0
          ? calculateCenter(gpsPoints)
          : {
              lat: props.selectedPremise.detail.latitude,
              lng: props.selectedPremise.detail.longitude,
            }
      }
      onLoad={onLoad}
      zoom={3}
      options={{
        controlSize: 25,
        streetViewControl: false,
        zoomControl: false,
      }}
    >
      {gpsPoints.map((point, index) => (
        <Marker
          options={{
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/green.png",
              fillColor: "#5DBC86",
            },
          }}
          key={index}
          position={point}
        />
      ))}
      {gpsPoints.length > 0 && (
        <Polygon
          paths={gpsPoints}
          options={{
            strokeColor: "#5DBC86",
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: "#5DBC86",
            fillOpacity: 0.5,
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MiniMap;
