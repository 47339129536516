import React from "react";
import { COLORS } from "../../utils/Colors";

interface IButton {
  title: string;
  width: number;
  height: number;
  onClick: () => void;
}

const Button = (props: IButton) => {
  return (
    <button
      className="w-10/12"
      onClick={props.onClick}
      style={{
        height: props.height,
        background: COLORS.Brand,
        borderRadius: 10,
        border: "none",
        color: COLORS.White,
      }}
    >
      {props.title}
    </button>
  );
};

export default Button;
